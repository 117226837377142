import React from 'react';

import { Link } from 'react-router-dom';
import { Transition } from 'react-transition-group';

import { ImageArch, LineButton, Typography } from '..';
import { ResponsiveMenu, OvalBorder, OvalHover } from './styled';
import { Grid } from '@mui/material';

import { colors } from '../../assets/colors';

import menuImage from '../../assets/images/menu/Studio-Valerie-Maas-grafisch-ontwerp-menu-ipad.jpg';

export default function PhoneMenu(props) {
    const { isPhone, isOpen, setOpen, bookingDate, logo } = props;

    const duration = 300;
    const defaultStyle = {
        transition: `transform ${duration}ms ease-in`,
        opacity: 0,
    }
    
    const transitionStyles = {
            enter: { opacity: 1, position: 'relative' },
            entering: {  opacity: 1, transform: 'translateX(100%)' },
            entered:  { opacity: 1 },
            exiting:  { opacity: 1, transform: 'translateX(100%)' },
            exited:  { opacity: 0, transform: 'translateX(100%)', position: 'absolute' },
    };

    return (
        <div>
            <Transition in={isOpen} timeout={duration}>
                {state => (
                    <div style={{ ...defaultStyle, ...transitionStyles[state] }}>
                        <ResponsiveMenu>
                            <div style={{ position: 'relative', height: isPhone ? '25vw' : '15vw' }}>
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5vw' }} onClick={() => setOpen(false)}>
                                    <Link to='/'>
                                        <img alt='logo' src={logo} style={{ height: isPhone ? '8vw' : '5vw'}} />
                                    </Link>
                                </div>
                                <div style={{ position: 'absolute', top: isPhone ? '17vw' : '10vw', right: '0' }} onClick={() => setOpen(false)}>
                                    <OvalBorder isOpen={isOpen}>
                                        <Typography variant='sub' style={{ color: colors.title}}>x</Typography>
                                    </OvalBorder>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flex: '1 1 auto', alignItems: 'center', justifyContent: 'center', gap: isPhone ? '3vw' : '15vw' }} onClick={() => setOpen(false)}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: isPhone ? 'center' : 'left', textAlign: 'center', gap: isPhone ? '3vw' : '1vw' }}>
                                    <LineButton to='/over' style={{ color: colors.title }}>Over</LineButton>
                                    <LineButton to='/portfolio' style={{ color: colors.title }}>Portfolio</LineButton>
                                    <LineButton to='/diensten' style={{ color: colors.title }}>Diensten</LineButton>
                                    <LineButton to='/werkwijze' style={{ color: colors.title }}>Werkwijze</LineButton>
                                    <LineButton to='/shop' style={{ color: colors.title }}>Shop</LineButton>
                                    <LineButton to='/blog' style={{ color: colors.title }}>Blog</LineButton>
                                    <LineButton to='/contact' style={{ color: colors.title }}>Contact</LineButton>
                                    {/* <Winkelwagen to='/shop/winkelwagen'>
                                        <Typography variant='sub' style={{ fontSize: '12px', color: colors.title}}>
                                            Winkelwagen ({winkelwagenCount})
                                        </Typography>
                                    </Winkelwagen> */}
                                </div>
                                {!isPhone && <ImageArch src={menuImage} width='30vw'/>}
                            </div>
                        </ResponsiveMenu>
                    </div>
                )}
            </Transition>
            <div style={{ position: 'relative', marginBottom: isPhone ? '15vw' : '5vw', height: isPhone ? '25vw' : '15vw' }}>
                {bookingDate ? <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <OvalHover href='/contact'>Boekingen open vanaf Q3 2025 - Plan nu je kennismaking </OvalHover>

                </Grid> : <div style={{ height: '2vw', width: '3vw'}} />}
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Link to='/'>
                        <img alt='logo' src={logo} style={{ height: isPhone ? '8vw' : '5vw'}} />
                    </Link>
                </div>
                <div style={{ position: 'fixed', top: isPhone ? '17vw' : '10vw', right: '0', zIndex: '1500'}} onClick={() => setOpen(true)}>
                    <OvalBorder>
                        <Typography variant='sub' style={{ color: colors.title}}>Menu</Typography>
                    </OvalBorder>
                </div>
            </div>
        </div>
    )
}