import styled from "styled-components";

import { Link } from "react-router-dom";

import { colors } from "../../assets/colors";

import { Grid } from '@mui/material';

export const DienstenHover = styled(Link)`
    display: flex;
    flex-direction: column;
    text-decoration: none;

    &:hover {
        cursor: pointer;
    }
`;

export const StickyDiv = styled.div`
    position: ${props => props.isTop ? 'static' : 'fixed'};
    top: ${props => props.isTop ? 'auto' : '0'};
    background-color: ${colors.background};
    z-index: 1002;
    width: 100%;

    margin-bottom: 5vw;
`;

export const ResponsiveMenu = styled.div`
    height: 100vh;
    position: absolute;
    margin: 0;
    top: 0;
    z-index: 2000;
    width: 100vw;
    background-color: #E9E9E5;
    display: flex;
    flex-flow: column;
`;

export const OvalBorder = styled.div`
    border-radius: 24px 0% 0% 24px;
    border: solid 1px ${colors.photo};
    border-right: 0px;
    padding: 5px 20px 5px 16px;
    background-color: ${colors.background};

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: ${props => props.isOpen ? '5px 35px 5px 16.5px' : '5px 14px 5px 15px'};
        background-color: ${props => props.isOpen ? colors.background : 'none'};
        border-color: ${colors.title};
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px) {
        padding: ${props => props.isOpen ? '5px 41.5px 5px 16.5px' : '5px 20px 5px 19px'};
        background-color: ${props => props.isOpen ? colors.background : 'none'};
        border-color: ${colors.title};
    }
`;

export const Winkelwagen = styled(Link)`
    text-decoration: none;
    margin-top: 5vh;
    padding: 7px 17px 8px 16px;
    border-radius: 24px;
    border: solid 1px #646a52;
`;

export const OvalHover = styled.a`
    font-family: Mulish;
    text-decoration: none;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    font-size: 13px;
    color: ${props => props.color || colors.title};
    background-color: ${props => props.backgroundcolor || colors.button};
    padding: 10px;
    border-radius: 18px;
    margin-top: 1vw;

    &:hover {
        color: white;
        background-color: ${props => props.backgroundcolorhover || colors.photo};
        cursor: pointer;
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        margin-top: 5vw;
        margin-bottom: 5vw;
        padding: 1vw 5vw;
        font-size: 11px;
        letter-spacing: 1.1px;
        text-align: center;
        max-width: 80vw;
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1024px){
        margin-top: 3vw;
        margin-bottom: 3vw;
        padding: 1vw 5vw;
        font-size: 11px;
        letter-spacing: 1.1px;
    }
`;

export const Diensten = styled.div`
    position: absolute;
    z-index: 1002;
    width: 100%;
    visibility: ${props => props.visibility};
    background-color: #F0EFEE;
    padding: 2vw 0;
`;

export const TextLink = styled(Link)`
    font-family: Mulish;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1.4px;
    font-weight: 400;
    font-size: 13px;
    color: ${props => props.type === 'winkelwagen' ? colors.title : colors.black };

    &:hover {
        font-weight: 600;
        cursor: pointer;
    }
`;

export const GridCenter = styled(Grid)`
    padding: 0 !important;
    text-align: center;
`;

export const Container = styled.div`
    position: absolute;
    height: 55px;
    display: flex;
    align-items: center;
    z-index: 1002;
    margin-top: -55px;
    right: 0;
`;