import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { StickyDiv, Diensten, TextLink, OvalHover, GridCenter, DienstenHover } from './styled';

import { LineButton, Typography } from '..';
import { Grid } from '@mui/material';

import fullbranding from '../../assets/images/diensten/branding/Studio-Valerie-Maas-grafisch-ontwerp-diensten-branding-full-branding.jpg';
import fullbrandingweb from '../../assets/images/diensten/branding/Studio-Valerie-Maas-grafisch-ontwerp-diensten-branding-full-branding-en-web.jpg';
import brandingstrategie from '../../assets/images/diensten/brandingstrategie/Studio-Valerie-Maas-grafisch-ontwerp-diensten-branding-strategie-1.jpg';

export default function LaptopMenu(props) {
    const [ isTop, setTop ] = useState(true);
    const [ showDiensten, setShowDiensten ] = useState(false);
    const [ showDate, setShowDate ] = useState(true);

    const { bookingDate, logo } = props;

    useEffect(() => {
        window.onscroll = () => {
            window.pageYOffset > 2 ? setTop(false) : setTop(true);
            return () => (window.onscroll = null);
        }

        const interval = setInterval(() => setShowDate(!showDate), 3000);
        return () => {
            clearInterval(interval);
        };
    });

    const DienstenImage = (props) => {
        return (
            <DienstenHover to={props.link} onClick={() => setShowDiensten(false)} >
                <img src={props.image} alt='diensten foto' style={{ width: '12vw', height: '12vw', marginBottom: '0.5vw' }} />
                <Typography variant='sub' margin='0' style={{ border: 'none', fontSize: '12px'}}>{props.title}</Typography>
            </DienstenHover>
        )
    }

    return (
        <StickyDiv isTop={isTop}>
            {isTop && bookingDate &&
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <OvalHover href='/contact'>Boekingen open vanaf Q3 2025 - Plan nu je kennismaking </OvalHover>
                </Grid>
            }
            <div style={{ margin: isTop ? '30px 0' : '10px 0', transition: 'all 1s ease 0s'}}>
                <Grid
                    container
                    justifyContent='center'
                    alignItems='center'
                    style={{ height: '55px', position: 'relative', zIndex: 100 }}
                >
                    <GridCenter item xs={1}>
                        <TextLink to='/over'>Over</TextLink>
                    </GridCenter>
                    <GridCenter item xs={1}
                        onMouseEnter={() => setShowDiensten(false)}
                    >
                        <TextLink to='/portfolio'>Portfolio</TextLink>
                    </GridCenter>
                    <GridCenter item xs={1}
                        onMouseEnter={() => setShowDiensten(true)}
                    >
                        <TextLink to='/diensten'>Diensten</TextLink>
                    </GridCenter>

                    <GridCenter item xs={2}
                        onMouseEnter={() => setShowDiensten(false)}
                    >
                        <Link to='/'>
                            <img alt='logo' src={logo} style={{ width: isTop ? '90px' : '60px'}} />
                        </Link>
                    </GridCenter>

                    <GridCenter item xs={1}>
                        <TextLink to='/werkwijze'>Werkwijze</TextLink>
                    </GridCenter>
                    <GridCenter item xs={1}>
                        <TextLink to='/shop'>Shop</TextLink>
                    </GridCenter>
                    <GridCenter item xs={1}>
                        <TextLink to='/contact'>Contact</TextLink>
                    </GridCenter>
                </Grid>
                {/* <Container> 
                    <OvalBorder>
                        <TextLink to='/shop/winkelwagen' type='winkelwagen'>Winkelwagen ({winkelwagenCount})</TextLink>
                    </OvalBorder>
                </Container> */}
            </div>
            <div
                onMouseEnter={() => setShowDiensten(true)}
                onMouseLeave={() => setShowDiensten(false)}
                onMouseOver={() => setShowDiensten(true)}
            >
                <Diensten
                    visibility={showDiensten ? 'visible' : 'hidden'}
                >
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '3vw'}}>
                        <div style={{ width: '17vw'}}>
                            <Typography variant='h2' style={{ fontSize: '25px', color: '#707070' }}>waar kan ik jou mee <br/> helpen?</Typography>
                            <LineButton to='/diensten' onClick={() => setShowDiensten(false)} style={{ margin: '0.8vw 0', fontSize: '12px'}}>Bekijk alle</LineButton>
                            <LineButton to='/diensten/brandingstrategie' onClick={() => setShowDiensten(false)} style={{ margin: '0.8vw 0', fontSize: '12px'}}>Branding strategie</LineButton>
                            <LineButton to='/diensten/branding' onClick={() => setShowDiensten(false)} style={{ margin: '0.8vw 0', fontSize: '12px'}}>Branding</LineButton>
                            <LineButton to='/diensten/creationboost' onClick={() => setShowDiensten(false)} style={{ margin: '0.8vw 0', fontSize: '12px'}}>Creation boost</LineButton>
                        </div>
                        <DienstenImage 
                            title='Branding strategie'
                            link='/diensten/brandingstrategie'
                            image={brandingstrategie}
                        />
                        <DienstenImage 
                            title='Full branding'
                            link='/diensten/fullbranding'
                            image={fullbranding}
                        />
                        <DienstenImage 
                            title='Full branding + web'
                            link='/diensten/fullbrandingweb'
                            image={fullbrandingweb}
                        />
                    </div>
                </Diensten>
            </div>
        </StickyDiv>
    )
}